import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { AxiosDelete, AxiosGet } from "../../../utils/api";
import toast from "react-hot-toast";
import ConfirmBox from "../../../utils/confirmBox";
import ReactPaginate from "react-paginate";
import { Country, State } from "country-state-city";

const pageSize = 10;

export default function Colleges() {
  const [articleData, setArticleData] = useState([]);
  const [reload, setReload] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [currentDeleteObject, setCurrentDeleteObject] = useState();
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [searchTerm, setSearchTerm] = useState("");
  const [allAuthors, setAllAuthors] = useState([]);
  const [authorFilter, setAuthorFilter] = useState(null);


  // Fetch all colleges
  const fetchAllColleges = async (page, searchQuery, authorFilter) => {
    try {
      const url = `/college/admin/all?page=${page}&size=${pageSize}&search=${searchQuery}&author=${authorFilter}`;
      const response = await AxiosGet(url);
      if (response?.success) {
        setArticleData(response.data);
        setTotalPages(response.pagination.totalPages);
      }
    } catch (error) {
      console.error('Error fetching colleges:', error);
    }
  };

  const fetchAllAuthors = async () => {
    try {
      const url = `/user/all`;
      const response = await AxiosGet(url);
      if (response?.success) {
        setAllAuthors(response.data);
        setTotalPages(response.pagination.totalPages);
      }
    } catch (error) {
      console.error('Error fetching colleges:', error);
    }
  };

  useEffect(() => {
    fetchAllColleges(currentPage, searchTerm, authorFilter);
    if(allAuthors?.length<1){
      fetchAllAuthors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reload, currentPage, searchTerm, authorFilter]);

  // Search function
  const search = (event) => {
    const searchTerm = event.target.value.toLowerCase();
    setSearchTerm(searchTerm);
  };

  // Delete article
  const deleteObject = async () => {
    if (currentDeleteObject) {
      try {
        const response = await AxiosDelete(`/college/${currentDeleteObject}`);
        if (response?.success) {
          toast.success(response.message);
          setReload(reload + 1);
        } else {
          toast.error(response?.message);
        }
      } catch (error) {
        console.error("Error deleting college:", error);
      }
    }
    setShowModal(false);
  };

  // Handle page change
  const handlePageChange = (selectedPage) => {
    setCurrentPage(selectedPage.selected);
  };


  // Reset search and fetch all colleges again
  const resetSearch = () => {
    setSearchTerm("");
    fetchAllColleges(currentPage, "");
  };

  let filteredData = articleData;

  return (
    <>
      <div className="relative overflow-x-auto overflow-y-hidden p-6 sm:rounded-lg">
        {/* Confirm Box */}
        {showModal && (
          <ConfirmBox open={showModal} setOpen={setShowModal} onDelete={deleteObject} />
        )}

        <div>
          <p className="text-left text-gray-400">
            <Link to="/dashboard">
              <i className="fa-solid fa-house"></i>
            </Link>
            &nbsp;<b>/</b>&nbsp;Colleges
          </p>
        </div>
        <div className="flex items-center justify-between py-4 bg-white dark:bg-gray-800">
          <div>
            <Link to="/college/add">
              <button className="px-4 py-2 bg-blue-500 rounded text-white">
                <i className="fa-solid fa-plus"></i> Add College
              </button>
            </Link>
          </div>
{/*          
          {userData?.role === "admin" && (
          <div className="bg-gray-100 rounded py-1 px-4">
            <label htmlFor="author">Author</label>
            <select name="author" className="border border-gray-500 rounded mx-4 px-4 py-1" onChange={(e)=>setAuthorFilter(e.target.value)}>
              <option value="">--Select--</option>
              {allAuthors?.length>0 && allAuthors?.map((item)=>(
                <option value={item?._id}>{item?.name}</option>
              ))}
            </select>
          </div>
          )} */}
          <label htmlFor="table-search" className="sr-only">
            Search
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <svg
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                aria-hidden="true"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              name="articleSearch"
              onChange={search}
              value={searchTerm}
              className="block p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg w-80 bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="Search for college"
            />
          </div>
          {searchTerm && (
            <button
              onClick={resetSearch}
              className="ml-2 px-3 py-1 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 focus:outline-none"
            >
              Clear
            </button>
          )}
        </div>
       
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border border-gray-400">
          <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
            <tr>
              <th scope="col" className="px-4 py-2">
                Sr
              </th>
              <th scope="col" className="px-4 py-2">
                Logo
              </th>
              <th scope="col" className="px-4 py-2">
                Name
              </th>
              <th scope="col" className="px-4 py-2">
                Location
              </th>
              <th scope="col" className="px-4 py-2">
                Status
              </th>
               <th scope="col" className="px-4 py-2">
                Created At
              </th>
              <th scope="col" className="px-4 py-2">
                Author
              </th>
              <th scope="col" className="px-4 py-2">
                Action
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.length > 0 &&
              filteredData.map((item, index) => (
                <tr
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700 hover:bg-gray-50 dark:hover:bg-gray-600"
                  key={index}
                >
                  <td className="px-4 py-1 font-semibold">
                    {currentPage * pageSize + index + 1} 
                  </td>
                  <td className="px-4 py-1 font-semibold text-black">
                    <img
                      src={`${process.env.REACT_APP_S3_BUCKET}/${item?.logo}`}
                      alt=""
                      className="w-10"
                    />
                  </td>
                  <td className="px-4 py-1 font-semibold text-black">
                    {item?.name}
                  </td>
                  <td className="px-4 py-1"> {typeof item.location[0] === "string" ? (
                      <span >
                        {item?.location[0]}
                      </span>
                    ) : (
                      <span >
                        {State.getStateByCodeAndCountry(item?.location[0]?.state,item?.location[0]?.country)?.name + ', ' + Country.getCountryByCode(item?.location[0]?.country)?.name  }
                      </span>
                    )}</td>
                  
                  <td className="px-4 py-1">
                    <div className="flex items-center">
                      {item.status ? (
                        <>
                          <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>{" "}
                          Publish
                        </>
                      ) : (
                        <>
                          <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div>{" "}
                          Draft
                        </>
                      )}
                    </div>
                  </td>
                  <td className="px-4 py-1">
                    {new Date(item?.createdAt).toLocaleString()}
                  </td>
                  <td className="px-4 py-1">
                    {item?.author?.name || "admin"}
                  </td>
                  <td className="px-4 py-1">
                    <Link
                      to={`/college/update/${item?._id}`}
                      type="button"
                      className="font-medium text-blue-600 p-1 dark:text-blue-500 hover:underline"
                   
                      >
                      Edit
                    </Link>
                    {userData?.role === "admin" && (
                      <Link
                        onClick={() => {
                          setCurrentDeleteObject(item?._id);
                          setShowModal(true);
                        }}
                        className="font-medium text-red-600 p-1 dark:hover:text-red-500 hover:underline ml-2"
                      >
                        Delete
                      </Link>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
        {/* Pagination */}
        <div style={{ marginTop: "30px" }}>
          <ReactPaginate
            previousLabel={"Previous"}
            nextLabel={"Next"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={totalPages}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageChange}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"activeLink"}
          />
        </div>
      </div>
    </>
  );
}

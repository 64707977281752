import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import arrow from "../../../assets/img/arrow.png";

export default function CollegeBar() {
  const param = useParams();
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [currentTab, setCurrentTab] = useState("");

  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/courses")) {
      setCurrentTab("courses");
    } else if (location.pathname.includes("/specialization")) {
      setCurrentTab("specialization");
    } else if (location.pathname.includes("/college/update/")) {
      setCurrentTab("basic-info");
    }
  }, [location.pathname]);

  const handleClickCourses = (e) => {
    if (param?.collegeId) {
      navigate("/courses/" + param.collegeId);
    } else {
      setMessage("Please fill in the College Information first.");
    }
  };

  const handleClickSpecialization = (e) => {
    if (param?.collegeId) {
      navigate("/specialization/" + param.collegeId);
    } else if (param?.collegeId === undefined) {
      setMessage("Fill the course first");
    } else {
      navigate("/courses/" + param.collegeId);
    }
  };

  const handleClickBasicInfo = (e) => {
    if (param?.collegeId) {
      navigate("/college/update/" + param.collegeId);
    } else {
      setMessage("Please add colleges first.");
    }
  };

  return (
    <div className="relative py-2 w-full px-4 bg-white flex items-center border-b border-gray-300 shadow-sm sm:py-1 sm:h-14 lg:h-16">
      <div className="flex w-full justify-between lg:justify-start lg:space-x-6">
        <button
          onClick={handleClickBasicInfo}
          className={`px-4 py-2 border border-gray-400 shadow text-sm sm:text-md lg:text-lg rounded transition-colors ${
            currentTab === "basic-info"
              ? "bg-indigo-600 text-white"
              : "bg-gray-100 text-black hover:bg-gray-200"
          }`}
        >
          Basic Information
        </button>
        <img
          src={arrow}
          alt="arrow"
          className="mx-2 sm:mx-4 h-3 sm:h-4 lg:h-5 hidden sm:inline mt-3"
        />

        {/* Courses Button */}
        <button
          onClick={handleClickCourses}
          className={`px-4 py-2 border border-gray-400 shadow text-sm sm:text-md lg:text-lg rounded transition-colors ${
            currentTab === "courses"
              ? "bg-indigo-600 text-white"
              : "bg-gray-100 text-black hover:bg-gray-200"
          }`}
        >
          Courses
        </button>
        {/* <img
          src={arrow}
          alt="arrow"
          className="mx-2 sm:mx-4 h-3 sm:h-4 lg:h-5 hidden sm:inline mt-3"
        /> */}
        {/* <button
          onClick={handleClickSpecialization}
          className={`px-4 py-2 border border-gray-400 shadow text-sm sm:text-md lg:text-lg rounded transition-colors ${
            currentTab === "specialization"
              ? "bg-indigo-600 text-white"
              : "bg-gray-100 text-black hover:bg-gray-200"
          }`}
        >
          Specialization
        </button> */}
      </div>

      {message && (
        <div className="absolute top-16 left-1/2 transform -translate-x-1/2 w-11/12 max-w-xl bg-red-100 border border-red-400 text-red-700 px-6 py-4 rounded shadow-md text-xs sm:text-sm lg:text-base">
          <div className="flex items-center justify-between">
            <strong className="font-bold">Notice:</strong>
            <button
              onClick={() => setMessage("")}
              className="text-red-700 font-bold hover:text-red-900"
            >
              ✕
            </button>
          </div>
          <span className="block mt-2">{message}</span>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { AxiosGet } from "../../../utils/api";
import { Link } from "react-router-dom";

const AllSpecialization = () => {
  const [course, setCourse] = useState([]);
  const fetchAllCourses = async () => {
    try {
      const response = await AxiosGet(`/college/specialization/all`);
      if (response?.AllSpecialization) {
        setCourse(response?.AllSpecialization);
      }
    } catch (error) {
      console.error("Error in fetching the courses");
    }
  };

  useEffect(() => {
    fetchAllCourses();
  });
  return (
    <div>
      <h3 className="text-2xl text-center font-semibold  mb-10">
        All Specializations
      </h3>
      <div>
        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
          <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" class="px-6 py-3">
                  S.No
                </th>
                <th scope="col" class="px-6 py-3">
                  Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Course Name
                </th>
                <th scope="col" class="px-6 py-3">
                  Created At{" "}
                </th>
                <th scope="col" class="px-6 py-3">
               Status
                </th>
                <th scope="col" class="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {course.length > 0 &&
                course.map((item, index) => (
                  <tr
                    class="odd:bg-white odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
                    key={index}
                  >
                    <th
                      scope="row"
                      class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                    >
                      {index + 1}
                    </th>
                    <td class="px-6 py-4 font-bold text-black">
                      {item?.specializationName}
                    </td>
                    <td class="px-6 py-4 font-bold text-black">
                      {item?.courseId?.courseName}
                    </td>
                    <td class="px-6 py-4 font-bold text-black">
                      {new Date(item?.createdAt).toLocaleString()}
                    </td>
                    <td className="px-4 py-1">
                    <div className="flex items-center">
                      {item.status ? (
                        <>
                          <div className="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"></div>{" "}
                          Publish
                        </>
                      ) : (
                        <>
                          <div className="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div>{" "}
                          Draft
                        </>
                      )}
                    </div>
                  </td>
                    <td class="px-6 py-4 font-bold text-black">
                      <Link
                        to={`/specialization/update/${item?._id}`}
                        class="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                      >
                        Edit
                      </Link>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default AllSpecialization;

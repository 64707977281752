import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { AxiosGet, AxiosPost } from "../../utils/api";
import loadingGif from "../../assets/gif/tenor.gif";

// List of common image file extensions
const imageExtensions = ["jpg", "jpeg", "png", "gif", "bmp"];
const pdfExtensions = ["pdf"];

export default function MediaPopup({ status, url, show }) {
  const [uploading, setuploading] = useState(false);
  const [allFiles, setAllFiles] = useState([]);
  const [reload, setReload] = useState(0);

  if(!show){
    show = 'img';
  }
  

  const getAllFiles = async () => {
    const response = await AxiosGet("/files/getAll");
    if (response?.success) {
      setAllFiles(response?.data.reverse());
    } else {
      toast.error("Something is wrong in getting all files");
    }
  };
  useEffect(() => {
     getAllFiles();
  }, [reload]);

  const fileUpload = async (e) => {
    const formdata = new FormData();
    formdata.append("file", e.target.files[0]);
    setuploading(true);
    const response = await AxiosPost("/files/upload", formdata);
    setuploading(false);
    if (response?.success) {
      toast.success("Upload Successfully");
      setReload(reload + 1);
    } else {
      toast.error("Something is wrong");
    }
  };


  return (
    <section>
      <div className="right-5 flex fixed top-16 bg-white ">
        <span
          className="bg-red-600 px-10 py-1 rounded-t text-white cursor-pointer"
          onClick={() => status(false)}
        >
          <i className="fa-solid fa-xmark"></i>
        </span>
      </div>
      <section className="overflow-x-auto shadow-lg p-5 rounded-lg rounded-t-none border border-blue-500 fixed top-24 w-[78vw] bg-white">
        {/* all images  */}
        <section  style={{overflowY:'scroll', maxHeight:'500px'}}>
          {/* upload image  */}
          {!uploading ? (
            <div className=" text-center w-[700px] h-[100px] m-auto cursor-pointer border-dashed border border-gray-500">
              <label
                htmlFor="imageFile"
                className="right-0 cursor-pointer left-0 top-10 absolute text-gray-400 text-lg"
              >
                Upload files here...
              </label>
              <input
                type="file"
                name="imageFile"
                onChange={fileUpload}
                className=" w-[700px] cursor-pointer h-[100px] opacity-0  rounded"
              />
            </div>
          ) : (
            // show loader for uploading files
            <div className="text-center">
              <img
                src={loadingGif}
                alt="uploading"
                className="m-auto w-[400px]"
              />
            </div>
          )}

          {/* show images  */}
          <div className="imageContainer mt-5">
            {allFiles?.length > 0 &&
              allFiles?.map((item, index) => (
                <div key={index}>
                  {/* ------Show only images-------- */}
                  {show==='img' && imageExtensions.includes(
                    item.split(".").pop().toLowerCase()
                  ) && (
                    <img
                      src={`${process.env.REACT_APP_S3_BUCKET}/${item}`}
                      width={200}
                      alt=""
                      className={"hover:border-2 border-blue-500"}
                      onClick={() => {
                        status(false);
                        url(item);
                      }}
                    />
                  )}
                   
                  {/* ------Show only pdf files-------- */}
                  {show==='pdf' && pdfExtensions.includes(
                    item.split(".").pop().toLowerCase()
                  ) && (
                    <div className="w-[250px] m-1 border cursor-pointer border-gray-200 hover:border-2 hover:border-gray-500" onClick={() => {
                      status(false);
                      url(item);
                    }}>
                      <p>{item.replace(/^\d+-/, "")}</p>
                    </div>
                  )}
                </div>
              ))}
          </div>
        </section>
      </section>
    </section>
  );
}

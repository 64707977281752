import React, { useEffect, useState } from "react";
import { AxiosPost, AxiosGet, AxiosDelete } from "../../../utils/api";
import { Link, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import SpecializationForm from "./SpecializationForm";
import CollegeBar from "../colleges/CollegeBar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Dialog } from "@headlessui/react";

const Specialization = () => {
  const [courses, setCourses] = useState([]);
  const [reload, setReload] = useState(0);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [submittedSpecializations, setSubmittedSpecializations] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [specializationToDelete, setSpecializationToDelete] = useState(null);
  const { collegeId } = useParams();
  const {courseId} = useParams();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await AxiosGet(
          `/college/course/CollegeId/${collegeId}`
        );
        console.log(response)
        setCourses(response?.Course || []);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    if (collegeId) {
      fetchCourses();
    }
  }, [collegeId, reload]);


  const validationSchema = Yup.object({
    specializationName: Yup.string().required(
      "Specialization Name is required"
    ),
    courseId: Yup.string().required("Please select a course"),
    content: Yup.string().required("Content is required").min(100),
  });

  const formik = useFormik({
    initialValues: {
      specializationName: "",
      courseId: courseId ||"",
      content: "",
    },
    validationSchema,
    onSubmit: async (values) => {
      const data = {
        specializationName: values.specializationName,
        content: values.content,
        courseId: values.courseId,
      };

      try {
        await AxiosPost("/college/specialization/create", data);
        toast.success("Specialization added successfully");
        formik.resetForm();
        setReload(reload + 1);
      } catch (error) {
        toast.error("Error in creating the specialization");
        console.error("Error submitting the data:", error);
      }
    },
  });

  const openDeleteDialog = (specializationId) => {
    setSpecializationToDelete(specializationId);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setSpecializationToDelete(null);
  };

  const handleDeleteSpecialization = async () => {
    try {
      await AxiosDelete(
        `/college/specialization/delete/${specializationToDelete}`
      );
      toast.success("Specialization deleted successfully");
      setSubmittedSpecializations((prev) =>
        prev.filter((spec) => spec._id !== specializationToDelete)
      );
      closeDeleteDialog();
    } catch (error) {
      toast.error("Error deleting the specialization");
      console.error("Error deleting specialization:", error);
    }
  };

  return (
    <div>
      <CollegeBar />
      <ToastContainer />

      <div className="">
        <h2 className="text-3xl text-center font-bold text-gray-800 mt-2 underline">
          Add Specialization
        </h2>
        <div className="max-w-5xl mx-auto bg-gray-50 p-3 rounded-lg shadow-lg">
          <form onSubmit={formik.handleSubmit} className="space-y-6">
            {/* Course Selection */}
            <div className="space-y-2">
              <label className="block text-lg font-medium text-gray-700">
                Selected Course
              </label>
              <h2 className="bg-white p-2 rounded-lg border border-gray-300">{courses[0]?.courseName}</h2>
           </div>

            {/* Specialization Name */}
            <div className="space-y-2">
              <label className="block text-lg font-medium text-gray-700">
                Specialization Name
              </label>
              <input
                className="block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="text"
                value={formik.values.specializationName}
                onChange={formik.handleChange}
                name="specializationName"
                placeholder="Enter Specialization Name"
              />
              {formik.errors.specializationName &&
                formik.touched.specializationName && (
                  <div className="text-red-600 font-bold">
                    {formik.errors.specializationName}
                  </div>
                )}
            </div>

            {/* Specialization Content */}
            <SpecializationForm formik={formik} />
          </form>
        </div>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={closeDeleteDialog}
        className="fixed inset-0 z-10 flex items-center justify-center"
      >
        <div
          className="bg-black bg-opacity-30 fixed inset-0"
          aria-hidden="true"
        />
        <div className="relative bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto z-20">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            Delete Specialization
          </Dialog.Title>
          <Dialog.Description className="mt-2 text-gray-500">
            Are you sure you want to delete this specialization? This action
            cannot be undone.
          </Dialog.Description>
          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={closeDeleteDialog}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteSpecialization}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Specialization;

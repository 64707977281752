import { useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { AxiosGet, AxiosPut } from "../../../utils/api";
import * as Yup from "yup";
import { CollegeTemplate } from "../../../utils/collegeTemplate";
import { Country, State } from "country-state-city";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MediaPopup from "../../media/MediaPopup";
import CollegeBar from "./CollegeBar";

export default function EditCollege() {
  const { collegeId } = useParams();
  const navigate = useNavigate();
  const [showMediaFiles, setShowMediaFiles] = useState(false);
  const [showLogoFiles, setShowLogoFiles] = useState(false);
  const [showPDFFiles, setShowPDFFiles] = useState(false);
  const [coverimage, setCoverImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [brochure, setBrochure] = useState(null);

  const fetchCollegeById = async () => {
    try {
      const response = await AxiosGet(`/college/${collegeId}`);
      if (response?.success) {
        const college = response.data;
        formik.setValues({
          name: college.name,
          slug: college.slug,
          subtitle: college.subtitle,
          content: college.content || CollegeTemplate,
          rating: college.rating,
          establishedIn: college.establishedIn,
          coverimage: college.coverimage,
          logo: college.logo,
          brochure: college.brochure,
          metaTitle: college.metaTitle,
          metaDesc: college.metaDesc,
          metaTags: college.metaTags,
          status: college.status,
          location: {
            country: college.location[0]?.country || "",
            state: college.location[0]?.state || "",
            city: college.location[0]?.city || "",
          },
        });
      }
    } catch (error) {

    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
      slug: "",
      subtitle: "",
      content: "",
      rating: 0,
      brochure: "NA",
      establishedIn: "",
      status: false,
      coverimage: null,
      logo: null,
      metaTitle: "",
      metaDesc: "",
      metaTags: "",
      location: {
        country: "",
        state: "",
        city: "",
      },
    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      slug: Yup.string().required("Slug is required"),
      subtitle: Yup.string().required("Subtitle is required"),
      rating: Yup.number()
        .required("Rating is required")
        .min(1, "Rating must be at least 1")
        .max(5, "Rating must be at most 5"),
      establishedIn: Yup.number()
        .required("Establishment year is required")
        .min(1800, "Establishment year must be at least 1800")
        .max(
          new Date().getFullYear(),
          `Cannot be after ${new Date().getFullYear()}`
        ),
      content: Yup.string().required("Content is required").min(100),
      coverimage: Yup.string().nullable(),
      logo: Yup.string().nullable(),
      status: Yup.string().required("Either it can be draft or publish "),
      location: Yup.object().shape({
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
      }),
    }),

    onSubmit: async (values) => {
      try {
        await AxiosPut(`/college/update/${collegeId}`, values);
        toast.success("College Updated Successfully");
        setTimeout(() => {
          navigate("/colleges");
        }, 1000);
      } catch (error) {
        window.scrollTo(0, 0);
        toast.error("Error in Updating the College");
     
      }
    },
  });

  useEffect(() => {
    if (coverimage) formik.setFieldValue("coverimage", coverimage);
    if (brochure) formik.setFieldValue("brochure", brochure);
    if (logo) formik.setFieldValue("logo", logo);
  }, [coverimage, brochure, logo]);

  useEffect(() => {
    fetchCollegeById();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collegeId]);

  // Generate slug from name
  const handleTitleChange = (e) => {
    formik.handleChange(e);
    const newSlug = e.target.value.toLowerCase().replace(/\s+/g, "-");
    formik.setFieldValue("slug", newSlug);
  };


  return (
    <>
    
      <ToastContainer />
      <CollegeBar/>
      <div
        className={`shadow-xl mt-6 p-6 font-serif mb-8 ${
          (showMediaFiles || showLogoFiles || showPDFFiles) && "blur-sm"
        }`}
      >
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-3 gap-4 m-auto w-full">
            <div className="col-span-2">
              {/* Title */}
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                className="block mb-2 px-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                onChange={handleTitleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                required
              />
              {formik.errors.name && formik.touched.name ? (
                <p className="text-red-600">{formik.errors.name}</p>
              ) : null}
              {/* Slug */}
              <div>
                <label htmlFor="slug">
                  Slug
                  <span className="text-sm ml-2 text-red-500">
                    (Should be Unique)
                  </span>
                </label>
                <input
                  type="text"
                  name="slug"
                  className="block px-2 mb-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.slug}
                  required
                />
                {formik.errors.slug && formik.touched.slug ? (
                  <p className="text-red-600">{formik.errors.slug}</p>
                ) : null}
              </div>

              {/* Subtitle */}
              <label htmlFor="subtitle">Subtitle</label>
              <input
                type="text"
                name="subtitle"
                className="block px-2 mb-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subtitle}
                required
              />
              {formik.errors.subtitle && formik.touched.subtitle ? (
                <p className="text-red-600">{formik.errors.subtitle}</p>
              ) : null}

              <div className="grid grid-cols-2 gap-2">
                <div>
                  <label htmlFor="establishedIn">Establishment Year</label>
                  <input
                    type="number"
                    name="establishedIn"
                    min="1800"
                    max={new Date().getFullYear()}
                    className="block px-2 mb-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                    onChange={(e) => {
                      const inputYear = e.target.value;
                      // Only allow input if it is a four-digit number
                      if (inputYear.length <= 4) {
                        formik.setFieldValue("establishedIn", inputYear);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.establishedIn}
                    required
                  />
                  {formik.errors.establishedIn &&
                  formik.touched.establishedIn ? (
                    <p className="text-red-600">
                      {formik.errors.establishedIn}
                    </p>
                  ) : null}
                </div>

                {/* Rating */}
                <div>
                  <label htmlFor="rating">Rating out of 5</label>
                  <br />
                  <input
                    type="number"
                    name="rating"
                    className="block px-2 mb-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rating}
                    required
                  />
                  {formik.errors.rating && formik.touched.rating ? (
                    <p className="text-red-600">{formik.errors.rating}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-between col-span-2 my-2">
                {/* Country Select */}
                <div>
                  <label>Country</label>
                  <br /> 
                  <select
                    className="border border-lg rounded-lg p-2 w-48"
                    value={formik.values.location.country}
                    onChange={(e) => formik.setFieldValue('location.country', e.target.value)}
                    required
                  >
                    <option value="">Select Country</option>
                    {Country.getAllCountries().map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.location?.country ? (
                    <p className="text-red-600">
                      {formik.errors.location?.country}
                    </p>
                  ) : null}
                </div>

                {/* State Select */}
                <div>
                  <label>State</label>
                  <br />
                  <select
                    className="border border-lg rounded-lg p-2 w-48"
                    value={formik.values.location.state}
                    onChange={(e) => formik.setFieldValue('location.state', e.target.value)}
                    required
                  > 
                    <option value="">Select State</option>
                    {State.getStatesOfCountry(formik.values.location?.country).map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.location?.state ? (
                    <p className="text-red-600">
                      {formik.errors.location?.state}
                    </p>
                  ) : null}
                </div>
              </div>

              {/* Brochure */}
              <label htmlFor="brochure">Brochure</label>
              <div
                onClick={() => setShowPDFFiles(true)}
                className="block cursor-pointer px-2 mb-2 py-1 h-9 w-full border-solid border-2 rounded border-gray-600"
              >
                <p>
                  {formik?.values?.brochure
                    ? formik?.values?.brochure.replace(/^\d+-/, "")
                    : "No file chosen"}
                </p>
              </div>
            </div>

            {/* Second part grid */}
            <div>
              {/* Status */}
              <div className="mt-6 border-2 mb-2 rounded border-gray-600 text-center">
                <div className="">
                  <div className="flex justify-between mx-10">
                    <label htmlFor="status" className="pt-1 text-lg pl-2">
                      Status :
                    </label>
                    <div className="ml-4">
                      <select
                        id="status"
                        name="status"
                        className="px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring focus:ring-blue-300 cursor-pointer"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option
                          value={false}
                          className="border-2 border-gray-600"
                        >
                          Draft
                        </option>
                        <option
                          value={true}
                          className="border-2 border-gray-600"
                        >
                          Publish
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              {/* Cover Image */}
              <label>Cover Image</label>
              <div className="border-2 border-gray-600 p-2 m-1 rounded-lg cursor-pointer ">
                <img
                  src={
                    formik.values.coverimage !== null
                      ? `${process.env.REACT_APP_S3_BUCKET}/${formik.values.coverimage}`
                      : "https://dummyimage.com/720x300/fff"
                  }
                  alt=""
                  className="w-full"
                  style={{ maxHeight: "150px" }}
                  onClick={() => setShowMediaFiles(true)}
                />
                {formik.errors.coverimage && formik.touched.coverimage ? (
                  <p className="text-red-600">{formik.errors.coverimage}</p>
                ) : null}
              </div>

              {/* Logo */}
              <label>Logo</label>
              <div className="border-2 border-gray-600 p-2 m-1 rounded-lg cursor-pointer ">
                <img
                  src={
                    formik.values.logo !== null
                      ? `${process.env.REACT_APP_S3_BUCKET}/${formik.values.logo}`
                      : "https://dummyimage.com/500x500/fff"
                  }
                  alt=""
                  className="w-full"
                  style={{ maxHeight: "150px" }}
                  onClick={() => setShowLogoFiles(true)}
                />
                {formik.errors.logo && formik.touched.logo ? (
                  <p className="text-red-600">{formik.errors.logo}</p>
                ) : null}
              </div>
            </div>
          </div>

          {/* Editor */}
          <JoditEditor

            value={formik.values.content}
            onChange={(newContent) => {
              formik.setFieldValue("content", newContent); // Update Formik state
            }}
            onBlur={() => formik.handleBlur("content")} // Trigger validation on blur
          />
   
          {formik.errors.content && formik.touched.content && (
            <div className="error">{formik.errors.content}</div>
          )}
          {/* Editor end */}

          <div>
            <button
              type="submit"
              className="bg-red-500 rounded-lg w-full p-4 text-white mt-4"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
      {/* Show Media Files for Cover Image */}
      {showMediaFiles && (
        <div>
          <MediaPopup status={setShowMediaFiles} url={setCoverImage} />
        </div>
      )}
      {/* Show Media Files for Logo */}
      {showLogoFiles && (
        <div>
          <MediaPopup status={setShowLogoFiles} url={setLogo} />
        </div>
      )}
      {/* Show Media Files for Brochure */}
      {showPDFFiles && (
        <div>
          <MediaPopup status={setShowPDFFiles} url={setBrochure} show={"pdf"} />
        </div>
      )}
    </>
  );
}

import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import * as Yup from "yup";
import MediaPopup from "../../media/MediaPopup";
import { useFormik } from "formik";
import toast from "react-hot-toast";
import { AxiosPost, AxiosPut } from "../../../utils/api";
import { CollegeTemplate } from "../../../utils/collegeTemplate";
import { Country, State, City } from "country-state-city";
import CollegeForm from "./CollegeForm";
import CollegeBar from "../colleges/CollegeBar"

// generating slug
const generateSlug = (value) => {
  const generatedSlug = value
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, "-") // Replace non-alphanumeric characters with dashes
    .replace(/^-+|-+$/g, ""); // Remove leading and trailing dashes
  return generatedSlug;
};

export default function AddCollege() {
  const param = useParams();
  const navigate = useNavigate();
  const [showMediaFiles, setShowMediaFiles] = useState(false);
  const [showLogoFiles, setShowLogoFiles] = useState(false);
  const [showPDFFiles, setShowPDFFiles] = useState(false);
  const [coverimage, setCoverImage] = useState(null);
  const [logo, setLogo] = useState(null);
  const [brochure, setBrochure] = useState(null);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  useEffect(() => {
    setCountries(Country.getAllCountries());
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
      setCities([]);
      formik.setFieldValue("location", {
        country: selectedCountry,
        state: "",
        city: "",
      });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
      formik.setFieldValue("location", {
        country: selectedCountry,
        state: selectedState,
        city: "",
      });
    }
  }, [selectedState, selectedCountry]);

  useEffect(() => {
    if (selectedCity) {
      formik.setFieldValue("location", {
        country: selectedCountry,
        state: selectedState,
        city: selectedCity,
      });
    }
  }, [selectedCity, selectedState, selectedCountry]);


  const formik = useFormik({
    initialValues: {
      name: "",
      slug: "",
      subtitle: "",
      content: CollegeTemplate,
      rating: 0,
      brochure: "NA",
      status: false,
      coverimage: null,
      logo: null,
      metaTitle: "",
      metaDesc: "",
      metaTags: "",
      author: userData?._id,
      location: {
        country: "",
        state: "",
        city: "",
      },

    },
    validationSchema: Yup.object().shape({
      name: Yup.string().required("Name is required"),
      slug: Yup.string().required("Slug is required"),
      subtitle: Yup.string().required("Subtitle is required"),
      rating: Yup.number()
        .required("Rating is required")
        .min(1, "Rating must be at least 1")
        .max(5, "Rating must be at most 5"),
      establishedIn: Yup.number()
        .required("Establishment year is required")
        .min(1800, "Establishment year must be at least 1800")
        .max(
          new Date().getFullYear(),
          ` Establishment year cannot be after ${new Date().getFullYear()}`
        ),
      content: Yup.string().required("Content is required").min(100),
    
      status: Yup.boolean().required(),
      coverimage: Yup.string().required("Cover image is required"),
      logo: Yup.string().required("Logo is required"),
      metaTitle: Yup.string(),
      metaDesc: Yup.string(),
      metaTags: Yup.string(),
      location: Yup.object().shape({
        country: Yup.string().required("Country is required"),
        state: Yup.string().required("State is required"),
      }),
    }),

    onSubmit: async (values) => {
      const errors = formik.errors;
      const firstErrorField = Object.keys(errors)[0]; // Get the first error key
    
      if (firstErrorField) {
        // Scroll to the first error field
        const errorElement = document.querySelector(`[name="${firstErrorField}"]`);
        if (errorElement) {
          errorElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
        toast.dismiss(toastLoading);
        return; // Prevent form submission if there are errors
      }
      const toastLoading = toast.loading("loading...");
      var response;
      if (param.id) {
        response = await AxiosPut(`/college/update/${param.id}`, values);
      } else {
        response = await AxiosPost("/college/create", values);
      }
      toast.dismiss(toastLoading);
      if (response?.success || response?.data?._id) {
        const collegeId = response.collegeToCreate._id;
        navigate('/courses/' + collegeId);
        toast.success(response?.message);
      } else if (
        response?.message ===
        'E11000 duplicate key error collection: test.articles index: slug_1 dup key: { slug: "my-first-post" }'
      ) {
        toast.error("Slug must be unique");
      } else {
        toast.error(response?.message);
      }
    },
  });

  // updating slug
  const handleTitleChange = (e) => {
    formik.handleChange(e);
    const newSlug = generateSlug(e.target.value);
    formik.setFieldValue("slug", newSlug);
  };


  // update coverimage image
  useEffect(() => {
    if (coverimage) {
      formik.setFieldValue("coverimage", coverimage);
    }
    if (brochure) {
      formik.setFieldValue("brochure", brochure);
    }
    if (logo) {
      formik.setFieldValue("logo", logo);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coverimage, brochure, logo]);


  return (
    <>
      <CollegeBar />
      <div
        className={`shadow-xl mt-6 p-6 font-serif mb-8 ${(showMediaFiles || showLogoFiles || showPDFFiles) && "blur-sm"}`}
      >
        <form onSubmit={formik.handleSubmit} >

          <div className="grid grid-cols-3 gap-4 m-auto w-full">
            <div className="col-span-2">
              {/* Title */}
              <label htmlFor="name">Name</label>
              <input
                type="text"
                name="name"
                className="block mb-2 px-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                onChange={handleTitleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                required
              />
              {formik.errors.name && formik.touched.name ? (
                <p className="text-red-600 font-bold">{formik.errors.name}</p>
              ) : null}
              {/* Slug */}
              <label htmlFor="slug">
                Slug
                <span className="text-sm ml-2 text-red-500">
                  (Should be Unique)
                </span>
              </label>
              <input
                type="text"
                name="slug"
                className="block px-2 mb-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.slug}
                required
              />
              {formik.errors.slug && formik.touched.slug ? (
                <p className="text-red-600 font-bold">{formik.errors.slug}</p>
              ) : null}
              {/* Subtitle */}
              <label htmlFor="subtitle">Subtitle</label>
              <input
                type="text"
                name="subtitle"
                className="block px-2 mb-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.subtitle}
                required
              />
              {formik.errors.subtitle && formik.touched.subtitle ? (
                <p className="text-red-600 font-bold">{formik.errors.subtitle}</p>
              ) : null}

              <div className="grid grid-cols-2 gap-2">
                <div>
                  <label htmlFor="establishedIn">Establishment Year</label>
                  <input
                    type="number"
                    name="establishedIn"
                    min="1800"
                    max={new Date().getFullYear()}
                    className="block px-2 mb-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                    onChange={(e) => {
                      const inputYear = e.target.value;
                      // Only allow input if it is a four-digit number
                      if (inputYear.length <= 4) {
                        formik.setFieldValue("establishedIn", inputYear);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.establishedIn}
                    required
                  />    
                  {formik.errors.establishedIn && formik.touched.establishedIn ? (
                    <p className="text-red-600 font-bold">{formik.errors.establishedIn}</p>
                  ) : null}
                </div>


                {/* Rating */}
                <div>
                  <label htmlFor="rating">Rating out of 5</label>
                  <br />
                  <input
                    type="number"
                    name="rating"
                    className="block px-2 mb-2 py-1 w-full border-solid border-2 rounded border-gray-600"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.rating}
                    required
                  />
                  {formik.errors.rating && formik.touched.rating ? (
                    <p className="text-red-600 font-bold">{formik.errors.rating}</p>
                  ) : null}
                </div>
              </div>
              <div className="flex justify-between col-span-2 my-2">
                <div>
                  <label>Country</label><br />
                  <select
                    className=" border border-lg rounded-lg p-2 w-48"
                    value={selectedCountry}
                    onChange={(e) => setSelectedCountry(e.target.value)}
                    required
                  >
                    <option value="">Select Country</option>
                    {countries.map((country) => (
                      <option key={country.isoCode} value={country.isoCode}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.location?.country ? (
                    <p className="text-red-600 font-bold">{formik.errors.location?.country}</p>
                  ) : null}
                </div>
                <div>
                  <label>State</label><br />
                  <select
                    className=" border border-lg rounded-lg p-2 w-48"
                    value={selectedState}
                    onChange={(e) => setSelectedState(e.target.value)}
                    required
                  >
                    <option value="">Select State</option>
                    {states.map((state) => (
                      <option key={state.isoCode} value={state.isoCode}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.location?.state ? (
                    <p className="text-red-600 font-bold">{formik.errors.location?.state}</p>
                  ) : null}
                </div>

              </div>


              {/* Brochure */}
              <label htmlFor="brochure">Brochure</label>
              <div
                onClick={() => setShowPDFFiles(true)}
                className="block cursor-pointer px-2 mb-2 py-1 h-9 w-full border-solid border-2 rounded border-gray-600"
              >
                <p>
                  {formik?.values?.brochure
                    ? formik?.values?.brochure.replace(/^\d+-/, "")
                    : "No file chosen"}
                </p>
              </div>

            </div>

            {/* Second part grid */}
            <div>
              {/* Status */}
              <div className="mt-6 border-2 mb-2 rounded border-gray-600 text-center">
              <div className="">
                  <div className="flex justify-between mx-10">
                    <label
                      htmlFor="status"
                      className="pt-1 text-lg pl-2"
                    >
                      Status :
                    </label>
                    <div className="ml-4">
                      <select
                        id="status"
                        name="status"
                        className="px-4 py-2 border border-gray-400 rounded focus:outline-none focus:ring focus:ring-blue-300 cursor-pointer"
                        value={formik.values.status}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option
                          value={false}
                          className="border-2 border-gray-600"
                        >
                          Draft
                        </option>
                        <option
                          value={true}
                          className="border-2 border-gray-600"
                        >
                          Publish
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

              </div>

              {/* Cover Image */}
              <label>Cover Image</label>
              <div className="border-2 border-gray-600 p-2 m-1 rounded-lg cursor-pointer ">
                <img
                  src={
                    formik.values.coverimage !== null
                      ? `${process.env.REACT_APP_S3_BUCKET}/${formik.values.coverimage}`
                      : "https://dummyimage.com/720x300/fff"
                  }
                  alt=""
                  className="w-full"
                  style={{ maxHeight: "150px" }}
                  onClick={() => setShowMediaFiles(true)}
                />
                {formik.errors.coverimage && formik.touched.coverimage ? (
                  <p className="text-red-600 font-bold">{formik.errors.coverimage}</p>
                ) : null}
              </div>

              {/* Logo */}
              <label>Logo</label>
              <div className="border-2 border-gray-600 p-2 m-1 rounded-lg cursor-pointer ">
                <img
                  src={
                    formik.values.logo !== null
                      ? `${process.env.REACT_APP_S3_BUCKET}/${formik.values.logo}`
                      : "https://dummyimage.com/500x500/fff"
                  }
                  alt=""
                  className="w-full"
                  style={{ maxHeight: "150px" }}
                  onClick={() => setShowLogoFiles(true)}
                />
                {formik.errors.logo && formik.touched.logo ? (
                  <p className="text-red-600 font-bold">{formik.errors.logo}</p>
                ) : null}
              </div>
            </div>
          </div>

          {/* Editor */}
          <CollegeForm formik={formik} />

          {formik.errors.content && formik.touched.content ? (
                    <p className="text-red-600 font-bold ">{formik.errors.content}</p>
                  ) : null}
              
          {/* Editor end */}
        </form>
      </div>

      {/* Show Media Files for Cover Image */}
      {showMediaFiles && (
        <div>
          <MediaPopup status={setShowMediaFiles} url={setCoverImage} />
        </div>
      )}
      {/* Show Media Files for Logo */}
      {showLogoFiles && (
        <div>
          <MediaPopup status={setShowLogoFiles} url={setLogo} />
        </div>
      )}
      {/* Show Media Files for Brochure */}
      {showPDFFiles && (
        <div>
          <MediaPopup status={setShowPDFFiles} url={setBrochure} show={'pdf'} />
        </div>
      )}
    </>
  );
}
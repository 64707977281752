import React, { useEffect } from "react";
import { useFormik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosGet, AxiosPut } from "../../../utils/api";
import * as Yup from "yup";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CollegeBar from "../colleges/CollegeBar";
const EditSpecialiization = () => {
  const { specializationId, collegeId } = useParams();
  const navigate = useNavigate();

  const fetchSpecialization = async () => {
    try {
      const response = await AxiosGet(
        `/college/specialization/${specializationId}`
      );
      formik.setValues({
        specializationName: response?.Specialization?.specializationName,
        content: response?.Specialization?.content,
        status: response?.Specialization?.status,
      });
    } catch (error) {
      console.error("Error in fetching the Specialization");
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      specializationName: "",
      content: "",
      status: "false",
    },
    validationSchema: Yup.object({
      specializationName: Yup.string().required(
        "Specialization Name is required"
      ),
      content: Yup.string().required("Content is required").min(100),
      status: Yup.boolean(),
    }),
    onSubmit: async (values) => {
      try {
        await AxiosPut(
          `/college/specialization/update/${specializationId}`,
          values
        );
        toast.success("updated");
        setTimeout(() => {
          navigate("/specialization/" + collegeId);
        }, 1000);
        window.scrollTo(0, 0);
        console.error("updated Successfully");
      } catch (error) {
        toast.error("not updating");
        console.error("Error in updating of Specialization");
      }
    },
  });

  useEffect(() => {
    fetchSpecialization();
  }, [specializationId]);

  return (
    <div className="">
      <ToastContainer />
      <CollegeBar />
      <div className=" text-center py-4 rounded-lg ">
        <h3 className="text-2xl font-bold underline">Edit Specialization</h3>
      </div>
      <div className="bg-white p-4 rounded-lg shadow-lg border border-gray-200 w-full">
        <form onSubmit={formik.handleSubmit} className="space-y-8">
          {/* Specialization Name */}
          <div className="space-y-2">
            <label className="block text-xl font-medium text-gray-700">
              Specialization Name
            </label>
            <input
              className="w-full border border-gray-300 px-4 py-3 rounded-lg shadow-sm focus:ring-2 focus:ring-red-500 focus:border-transparent transition duration-200 ease-in-out"
              type="text"
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              value={formik.values.specializationName}
              name="specializationName"
              placeholder="Enter Specialization Name"
            />
            {formik.errors.specializationName &&
              formik.touched.specializationName && (
                <div className="text-red-600 text-lg font-bold ">
                  {formik.errors.specializationName}
                </div>
              )}
          </div>

          {/* Specialization Content */}
          <div className="space-y-2">
            <label className="block text-xl font-medium text-gray-700">
              Specialization Content
            </label>
            <div className="border border-gray-300 rounded-lg shadow-sm">
              <JoditEditor
                value={formik.values.content}
                onChange={(newContent) => {
                  formik.setFieldValue("content", newContent);
                }}
                onBlur={() => formik.handleBlur("content")}
              />
            </div>
            {formik.errors.content && formik.touched.content && (
              <div className="text-red-600 font-bold text-lg">
                {formik.errors.content}
              </div>
            )}
          </div>
          {/* Status */}
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2 text-gray-600">
              Status
            </label>
            <select
              className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="status"
            >
              <option value="false">Draft</option>
              <option value="true">Published</option>
            </select>
          </div>

          {/* Submit Button */}
          <div className="mt-6">
            <button
              type="submit"
              className="w-full py-3 px-6 bg-gradient-to-r from-red-500 to-pink-500 text-white text-lg font-semibold rounded-lg shadow-md hover:bg-gradient-to-l hover:from-pink-500 hover:to-red-500 transition duration-300 ease-in-out"
            >
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EditSpecialiization;

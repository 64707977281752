import React, { useEffect, useState } from "react";
import { AxiosPost, AxiosGet, AxiosDelete } from "../../../utils/api"; // Add AxiosDelete for delete
import { Link, useParams } from "react-router-dom";
import CoursesForm from "./CoursesForm";
import { useFormik } from "formik";
import * as Yup from "yup";
import CollegeBar from "../colleges/CollegeBar";
import { ToastContainer, toast } from "react-toastify";
import { Dialog } from "@headlessui/react"; // Import Dialog from Headless UI
import "react-toastify/dist/ReactToastify.css";

const Courses = () => {
  const { collegeId } = useParams();
  const [reload, setReload] = useState(0);
  const userData = JSON.parse(localStorage.getItem("user"));
  const [submittedCourses, setSubmittedCourses] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [selectedCourseId, setSelectedCourseId] = useState(null);

  // Fetch submitted courses
  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await AxiosGet(
          `/college/course/CollegeId/${collegeId}`
        );
        const courses = response?.Course || [];
        setSubmittedCourses(courses);
      } catch (error) {
        toast.error("Failed to load courses");
      }
    };
    fetchCourses();
  }, [collegeId, reload]);

  // Formik for form handling
  const formik = useFormik({
    initialValues: {
      courseName: "",
      fees: "",
      duration: "",
      content: "",
      courseLevel: "graduate",
      status: "false",
      eligibility: "",
      modeOfStudy: "offline",
      ranking: "",
      cutoff: "",
    },
    validationSchema: Yup.object({
      courseName: Yup.string()
        .required("Course Name is required")
        .min(2, "Course Name must be at least 2 characters"),
      fees: Yup.number()
        .required("Fees are required")
        .min(1, "Fees must be a positive number"),
      duration: Yup.number()
        .required("Duration is required")
        .min(1, "At least select 1")
        .max(7, "At max select 7"),
      eligibility: Yup.string().required("Eligibility criteria are required"),
      ranking: Yup.string().required("Ranking is required"),
      cutoff: Yup.string().required("Cutoff is required"),
      status: Yup.boolean().required("Either it can be draft or Published"),
      content: Yup.string().required().min(100),
    }),
    onSubmit: async (values) => {
      const data = { ...values, collegeId };
      try {
        await AxiosPost("/college/course/create", data);
        toast.success("Form Submitted Successfully");
        formik.resetForm();
        setReload(reload + 1);
      } catch (error) {
        toast.error("Error in submitting the form");
      }
    },
  });

  // Open Delete Confirmation Dialog
  const openDeleteDialog = (courseId) => {
    setSelectedCourseId(courseId);
    setIsDeleteOpen(true);
  };

  const handleDeleteCourse = async () => {
    try {
      await AxiosDelete(`/college/course/delete/${selectedCourseId}`);
      setSubmittedCourses((prev) =>
        prev.filter((course) => course._id !== selectedCourseId)
      );
      toast.success("Course deleted successfully");
    } catch (error) {
      toast.error("Failed to delete course");
    } finally {
      setIsDeleteOpen(false);
    }
  };

  return (
    <div className="m-auto">
      <ToastContainer />
      <CollegeBar />
      <div className="container mx-auto max-w-7xl p-3 bg-white rounded-lg shadow-md">
        <h3 className="text-xl font-semibold text-gray-800 mb-2">
          All Courses
        </h3>

        {submittedCourses.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {submittedCourses.map((course) => (
              <div
                key={course._id}
                className="p-2 border border-gray-200 rounded-lg shadow-sm transition-transform hover:scale-105 hover:shadow-lg hover:bg-blue-50 cursor-pointer"
              >
                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-sm font-medium text-gray-900">
                      {course.courseName}
                    </div>
                    <div className="text-gray-600 text-sm">
                      {course.collegeName}
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <Link
                      to={`/courses/update/${collegeId}/${course._id}`}
                      className=" px-3 py-1 py-1 text-white bg-blue-500 rounded hover:bg-blue-600 transition"
                    >
                      Edit
                    </Link>
                    {userData?.role === "admin" && (
                      <button
                        className="px-2 py-1 text-white bg-red-500 rounded hover:bg-red-600 transition"
                        onClick={() => openDeleteDialog(course._id)}
                      >
                        Delete
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-500 text-center">
            No submitted courses available
          </p>
        )}
      </div>

    

      <div className="m-auto max-w-7xl mx-auto p-6 bg-white rounded-lg shadow-lg border-2 border-[crimson]">
      <div className="pb-10">
        <h2 className="  font-bold w-full text-center text-4xl rounded-lg underline">
          Add Course
        </h2>
      </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="grid grid-cols-1 lg:grid grid-cols-2 gap-4">
            {/* Course Level */}
            <div className="mb-6">
              <label className="block text-xl font-semibold mb-2">
                Course Level
              </label>
              <select
                className="border border-gray-300 w-full p-3 rounded-lg"
                value={formik.values.courseLevel}
                onChange={formik.handleChange}
                name="courseLevel"
              >
                <option value="graduate">Graduate</option>
                <option value="postgraduate">Postgraduate</option>
                <option value="phd">PhD</option>
                <option value="diploma">Diploma</option>
                <option value="certification">Certification</option>
              </select>
              {formik.errors.courseLevel && formik.touched.courseLevel ? (
                <p className="text-red-600 font-bold">
                  {formik.errors.courseLevel}
                </p>
              ) : null}
            </div>

            {/* Course Name */}
            <div className="mb-6">
              <label className="block text-xl font-semibold mb-2">
                Course Name
              </label>
              <input
                className="border border-gray-300 w-full p-3 rounded-lg"
                value={formik.values.courseName}
                onChange={formik.handleChange}
                placeholder="Enter Course Name"
                name="courseName"
              />
              {formik.errors.courseName && formik.touched.courseName ? (
                <p className="text-red-600 font-bold">
                  {formik.errors.courseName}
                </p>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-1 lg:grid grid-cols-2 gap-4">
            
              {/* Fees */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Fees</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="number"
              value={formik.values.fees}
              onChange={formik.handleChange}
              placeholder="Enter course fees"
              name="fees"
            />
            {formik.errors.fees && formik.touched.fees ? (
              <p className="text-red-600 font-bold">{formik.errors.fees}</p>
            ) : null}
          </div>

          {/* Duration */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Duration</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="text"
              value={formik.values.duration}
              onChange={formik.handleChange}
              placeholder="Enter course duration (e.g., 3 years)"
              name="duration"
            />
            {formik.errors.duration && formik.touched.duration ? (
              <p className="text-red-600 font-bold">{formik.errors.duration}</p>
            ) : null}
          </div>  
            
            </div>
            <div className="grid grid-cols-1 lg:grid grid-cols-2 gap-4">
              
                {/* Eligibility */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">
              Eligibility
            </label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="text"
              value={formik.values.eligibility}
              onChange={formik.handleChange}
              placeholder="Enter eligibility criteria"
              name="eligibility"
            />
            {formik.errors.eligibility && formik.touched.eligibility ? (
              <p className="text-red-600 font-bold">
                {formik.errors.eligibility}
              </p>
            ) : null}
          </div>

          {/* Mode of Study */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">
              Mode of Study
            </label>
            <select
              className="border border-gray-300 w-full p-3 rounded-lg"
              value={formik.values.modeOfStudy}
              onChange={formik.handleChange}
              name="modeOfStudy"
            >
              <option value="online">Online</option>
              <option value="offline">Offline</option>
            </select>
            {formik.errors.modeOfStudy && formik.touched.modeOfStudy ? (
              <p className="text-red-600 font-bold">
                {formik.errors.modeOfStudy}
              </p>
            ) : null}
          </div>
              
              </div>

              <div className="grid grid-cols-1 lg:grid grid-cols-2 gap-4">
   {/* Ranking */}
   <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Ranking</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="text"
              value={formik.values.ranking}
              onChange={formik.handleChange}
              placeholder="Enter ranking"
              name="ranking"
            />
            {formik.errors.ranking && formik.touched.ranking ? (
              <p className="text-red-600 font-bold">{formik.errors.ranking}</p>
            ) : null}
          </div>

          {/* Cutoff */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Cutoff</label>
            <input
              className="border border-gray-300 w-full p-3 rounded-lg"
              type="text"
              value={formik.values.cutoff}
              onChange={formik.handleChange}
              placeholder="Enter cutoff"
              name="cutoff"
            />
            {formik.errors.cutoff && formik.touched.cutoff ? (
              <p className="text-red-600 font-bold">{formik.errors.cutoff}</p>
            ) : null}
          </div>
                </div>    
          {/* Course Information */}
          <CoursesForm formik={formik} />

          {/* Status */}
          <div className="mb-6">
            <label className="block text-xl font-semibold mb-2">Status</label>
            <select
              className="border border-gray-300 w-full p-3 rounded-lg"
              value={formik.values.status}
              onChange={formik.handleChange}
              name="status"
            >
              <option value="false">Draft</option>
              <option value="true">Published</option>
            </select>
          </div>
        </form>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={isDeleteOpen} onClose={() => setIsDeleteOpen(false)}>
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-md">
            <Dialog.Title className="text-2xl font-semibold mb-4">
              Confirm Deletion
            </Dialog.Title>
            <Dialog.Description className="mb-4">
              Are you sure you want to delete this course? This action cannot be
              undone.
            </Dialog.Description>
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-500 text-white px-4 py-2 rounded hover:bg-gray-600"
                onClick={() => setIsDeleteOpen(false)}
              >
                Cancel
              </button>
              <button
                className="bg-red-500 text-white px-4 py-2 rounded hover:bg-red-600"
                onClick={handleDeleteCourse}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Courses;

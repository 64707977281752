import axios from "axios";

const api = process.env.REACT_APP_API;

// Handle Catch Error
const catchError = (error)=>{
  if(error?.response?.data){
    return error?.response?.data;
  }else{
    return error;
  }
}

// user login 
export async function userLogin(data) {
  try {
    const resp = await axios.post(`${api}/user/login`,data, { withCredentials: true });
    return resp?.data;
  } catch (error) {
    return catchError();
  }
}

// user logout
export async function userLogout() {
  try {
    const resp = await axios.get(`${api}/user/logout`,{ withCredentials: true });
    return resp?.data;
  } catch (error) {
    return error?.response?.data;
  }
}

// Refresh the token
export async function userRefresh() {
  try {
    const resp = await axios.get(`${api}/user/refresh`,{ withCredentials: true });
    return resp?.data;
  } catch (error) {
    return error?.response?.data;
  }
}

// ---------------- Re Usable Api Call -----------------
export async function AxiosPost(url,data){
  try {
    const resp = await axios.post(`${api}${url}`,data,{ withCredentials: true });
    return resp.data;
  } catch (error) {
    return error?.response?.data;
  }
}
export async function AxiosPut(url,data){
  try {
    const resp = await axios.put(`${api}${url}`,data,{ withCredentials: true });
    return resp.data;
  } catch (error) {
    return error?.response?.data;
  }
}
export async function AxiosGet(url){
  try {
    const resp = await axios.get(`${api}${url}`,{ withCredentials: true });
    return resp.data;
  } catch (error) {
    return error?.response?.data;
  }
}
export async function AxiosDelete(url){
  try {
    const resp = await axios.delete(`${api}${url}`,{ withCredentials: true });
    return resp.data;
  } catch (error) {
    return error?.response?.data;
  }
}

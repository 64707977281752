import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosDelete, AxiosGet, AxiosPut } from "../../../utils/api";
import JoditEditor from "jodit-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CollegeBar from "../colleges/CollegeBar";
import { IoAddCircle } from "react-icons/io5";
import { Dialog } from "@headlessui/react";
const EditCourses = () => {
  const { collegeId } = useParams();
  const { courseId } = useParams();
  const [reload, setReload] = useState(0);
  const navigate = useNavigate();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [submittedSpecializations, setSubmittedSpecializations] = useState([]);
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [specializationToDelete, setSpecializationToDelete] = useState(null);

  useEffect(() => {
    const fetchCourseById = async () => {
      try {
        const response = await AxiosGet(`/college/course/${courseId}`);
        setCourseData(response?.Course);

        formik.setValues({
          courseName: response?.Course?.courseName,
          fees: response?.Course.fees,
          duration: response?.Course.duration,
          modeOfStudy: response?.Course.modeOfStudy,
          ranking: response?.Course.ranking,
          eligibility: response?.Course.eligibility,
          cutoff: response?.Course.cutoff,
          courseLevel: response?.Course.courseLevel,
          content: response?.Course.content,
          status: response?.Course.status,
        });
      } catch (error) {
        console.error("Error fetching the course:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseById();
  }, [courseId]);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      courseName: "",
      fees: "",
      duration: "",
      content: "",
      courseLevel: "graduate",
      status: "false",
      eligibility: "",
      modeOfStudy: "offline",
      ranking: "",
      cutoff: "",
    },
    validationSchema: Yup.object({
      courseName: Yup.string()
        .required("Course Name is required")
        .min(2, "Course Name must be at least 2 characters"),
      fees: Yup.number()
        .required("Fees are required")
        .min(1, "Fees must be a positive number"),
      duration: Yup.number().required("Duration is required").min(1).max(7),
      content: Yup.string().required().min(100),
      courseLevel: Yup.string().required(),
      status: Yup.boolean(),
      modeOfStudy: Yup.string().required(),
      eligibility: Yup.string().required("Eligibility criteria are required"),
      ranking: Yup.string().required("Ranking is required"),
      cutoff: Yup.string().required("Cutoff is required"),
    }),
    onSubmit: async (values) => {
      try {
        await AxiosPut(`/college/course/update/${courseId}`, values);

        setTimeout(() => {
          navigate(`/courses/${collegeId}`);
        }, 1000);
        toast.success("Course Updated Successfully");
        window.scrollTo(0, 0);
      } catch (error) {
        window.scrollTo(0, 0);

        toast.error(
          error.response?.data || "An error occurred while updating the course."
        );
      }
    },
  });
  useEffect(() => {
    const fetchSpecializations = async () => {
      try {
        const response = await AxiosGet(
          `/college/specialization/course/${courseId}`
        );
        console.log(response);
        setSubmittedSpecializations(response?.specializations || []);
      } catch (error) {
        console.error("Error fetching specializations:", error);
      }
    };

    if (courseId) {
      fetchSpecializations();
    }
  }, [courseId, reload]);

  const handleTop = () => {
    window.scrollTo(0, 0);
  };
  const openDeleteDialog = (specializationId) => {
    setSpecializationToDelete(specializationId);
    setIsDialogOpen(true);
  };

  const closeDeleteDialog = () => {
    setIsDialogOpen(false);
    setSpecializationToDelete(null);
  };
  const handleDeleteSpecialization = async () => {
    try {
      await AxiosDelete(
        `/college/specialization/delete/${specializationToDelete}`
      );
      toast.success("Specialization deleted successfully");
      setSubmittedSpecializations((prev) =>
        prev.filter((spec) => spec._id !== specializationToDelete)
      );
      closeDeleteDialog();
    } catch (error) {
      toast.error("Error deleting the specialization");
      console.error("Error deleting specialization:", error);
    }
  };

  if (loading) return <div>Loading...</div>;

  return (
    <div className="">
      <ToastContainer />
      <CollegeBar />

      <div className="mx-auto p-6 bg-white rounded-lg shadow-lg w-full">
        <h2 className="text-3xl font-bold text-center mb-8 text-gray-700">
          Update Course Information
        </h2>
        <div className=" mx-auto w-full p-3 bg-white rounded-lg  border-2 border-dashed mb-5">
          <h3 className="text-xl font-semibold text-gray-800 mb-2 text-center ">
            All Specializations
          </h3>
          <div className="flex">
            <div className="">
              <Link to={`/specialization/${collegeId}/${courseId}`}>
                <IoAddCircle size={"50px"} color={"green"} />
              </Link>
            </div>
            <div>
              {submittedSpecializations.length > 0 ? (
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
                  {submittedSpecializations.map((specialization) => (
                    <div
                      key={specialization._id}
                      className="p-2 border border-gray-300 rounded-lg shadow transition-transform hover:scale-105 hover:shadow-md hover:bg-blue-50 cursor-pointer flex justify-between items-center"
                    >
                      <div>
                        <div className="text-sm font-medium text-gray-900">
                          {specialization.specializationName} -{" "}
                          {specialization?.courseId?.courseName}
                        </div>
                      </div>

                      <div className="flex space-x-4">
                        <Link
                          to={`/specialization/update/${collegeId}/${courseId}/${specialization._id}`}
                          className="px-2 py-1 bg-blue-500 text-white rounded hover:bg-blue-600 transition"
                        >
                          Edit
                        </Link>

                        {userData?.role === "admin" && (
                          <button
                            className="px-2 py-1 bg-red-500 text-white rounded hover:bg-red-600 transition"
                            onClick={() => openDeleteDialog(specialization._id)}
                          >
                            Delete
                          </button>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <p className="text-gray-500 text-center">
                  No submitted specializations available
                </p>
              )}
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit} className="space-y-6">
          {/* Course Level */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2 text-gray-600">
                Course Level
              </label>
              <select
                className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
                value={formik.values.courseLevel}
                onChange={formik.handleChange}
                name="courseLevel"
              >
                <option value="" disabled>
                  --Select Course--
                </option>
                <option value="graduate">Graduate</option>
                <option value="postgraduate">Postgraduate</option>
                <option value="phd">PhD</option>
                <option value="diploma">Diploma</option>
                <option value="certification">Certification</option>
              </select>
              {formik.errors.courseLevel && formik.touched.courseLevel ? (
                <p className="text-red-600 text-lg font-bold">
                  {formik.errors.courseLevel}
                </p>
              ) : null}
            </div>

            {/* Course Name */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2 text-gray-600">
                Course Name
              </label>
              <input
                className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.courseName}
                placeholder="Enter Course Name"
                name="courseName"
              />
              {formik.errors.courseName && formik.touched.courseName ? (
                <p className="text-red-600 text-lg font-bold">
                  {formik.errors.courseName}
                </p>
              ) : null}
            </div>
          </div>

          {/* Fees and Duration */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2 text-gray-600">
                Fees
              </label>
              <input
                className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
                type="number"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.fees}
                placeholder="Enter course fees"
                name="fees"
              />
              {formik.errors.fees && formik.touched.fees ? (
                <p className="text-red-600 text-lg font-bold">
                  {formik.errors.fees}
                </p>
              ) : null}
            </div>

            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2 text-gray-600">
                Duration
              </label>
              <input
                className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.duration}
                placeholder="Enter course duration (e.g., 3 years)"
                name="duration"
              />
              {formik.errors.duration && formik.touched.duration ? (
                <p className="text-red-600 text-lg font-bold">
                  {formik.errors.duration}
                </p>
              ) : null}
            </div>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            {/* Eligibility */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2 text-gray-600">
                Eligibility
              </label>
              <input
                className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.eligibility}
                placeholder="Enter eligibility criteria"
                name="eligibility"
              />
              {formik.errors.eligibility && formik.touched.eligibility ? (
                <p className="text-red-600 text-lg font-bold">
                  {formik.errors.eligibility}
                </p>
              ) : null}
            </div>

            {/* Mode of Study */}
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2 text-gray-600">
                Mode of Study
              </label>
              <select
                className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.modeOfStudy}
                name="modeOfStudy"
              >
                <option value="online">Online</option>
                <option value="offline">Offline</option>
              </select>
              {formik.errors.modeOfStudy && formik.touched.modeOfStudy ? (
                <p className="text-red-600 text-lg font-bold">
                  {formik.errors.modeOfStudy}
                </p>
              ) : null}
            </div>
          </div>

          {/* Ranking and Cutoff */}
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2 text-gray-600">
                Ranking
              </label>
              <input
                className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.ranking}
                placeholder="Enter ranking"
                name="ranking"
              />

              {formik.errors.ranking && formik.touched.ranking ? (
                <p className="text-red-600 text-lg font-bold">
                  {formik.errors.ranking}
                </p>
              ) : null}
            </div>

            <div className="mb-6">
              <label className="block text-lg font-semibold mb-2 text-gray-600">
                Cutoff
              </label>
              <input
                className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.cutoff}
                placeholder="Enter cutoff"
                name="cutoff"
              />
              {formik.errors.cutoff && formik.touched.cutoff ? (
                <p className="text-red-600 text-lg font-bold">
                  {formik.errors.cutoff}
                </p>
              ) : null}
            </div>
          </div>

          {/* Content (using JoditEditor) */}
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2 text-gray-600">
              Content
            </label>
            <JoditEditor
              value={formik.values.content}
              onChange={(newContent) => {
                formik.setFieldValue("content", newContent);
              }}
              onBlur={() => formik.handleBlur("content")}
            />
            {formik.errors.content && formik.touched.content && (
              <p className="text-red-600 text-lg font-bold">
                {formik.errors.content}
              </p>
            )}
          </div>

          {/* Status */}
          <div className="mb-6">
            <label className="block text-lg font-semibold mb-2 text-gray-600">
              Status
            </label>
            <select
              className="border border-gray-300 w-full p-3 rounded-lg bg-gray-50 focus:ring-2 focus:ring-red-500 focus:border-red-500"
              value={formik.values.status}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="status"
            >
              <option value="false">Draft</option>
              <option value="true">Published</option>
            </select>
          </div>

          <div>
            <button
              onClick={handleTop}
              type="submit"
              className="w-full bg-red-500 hover:bg-red-600 text-white text-lg font-semibold py-4 rounded-lg transition-colors duration-300"
            >
              Update Course
            </button>
          </div>
        </form>
      </div>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={isDialogOpen}
        onClose={closeDeleteDialog}
        className="fixed inset-0 z-10 flex items-center justify-center"
      >
        <div
          className="bg-black bg-opacity-30 fixed inset-0"
          aria-hidden="true"
        />
        <div className="relative bg-white rounded-lg shadow-lg p-6 max-w-md mx-auto z-20">
          <Dialog.Title className="text-lg font-medium text-gray-900">
            Delete Specialization
          </Dialog.Title>
          <Dialog.Description className="mt-2 text-gray-500">
            Are you sure you want to delete this specialization? This action
            cannot be undone.
          </Dialog.Description>
          <div className="mt-4 flex justify-end space-x-4">
            <button
              onClick={closeDeleteDialog}
              className="px-4 py-2 bg-gray-300 rounded hover:bg-gray-400"
            >
              Cancel
            </button>
            <button
              onClick={handleDeleteSpecialization}
              className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
            >
              Delete
            </button>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default EditCourses;
